<template>
  <div class="article-paragraph">
    <div class="article-paragraph__kpis">
      <span
        class="article-paragraph__kpis__kpi"
        v-for="kpi of kpis"
        :key="kpi.label"
      >
        {{ kpi.label }}
        <span class="article-paragraph__kpis__kpi__value">
          {{ kpi.value }}
        </span>
      </span>
    </div>

    <p class="article-paragraph__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    kpis: Array,
  },
};
</script>

<style lang="scss" scoped>
.article-paragraph {
  margin-bottom: toRem(51);

  &__text {
    @include paragraph-l;

    margin-bottom: toRem(10);
  }

  &__kpis {
    @include dida-s;

    margin-bottom: toRem(11);

    &__kpi {
      &:first-child {
        padding-right: 1rem;
        margin-right: 1rem;
        // border-right: toRem(1) solid $basic-black;
      }

      &__value {
        @include kpi-m;

        font-style: italic;
        font-weight: 500;
      }
    }
  }
}

.desktop {
  .article-paragraph {
    &__text {
      font-size: 1.25rem;
    }
  }
}
</style>
