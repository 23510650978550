<template>
  <main class="page-frame" :style="{ '--page-color': color }">
    <img
      v-if="illustration"
      class="page-frame__illustration"
      :src="`${$baseUrl}images/article/${illustration}.svg`"
      alt=""
    />
    <ConstitutionCard
      class="page-frame__side-nav"
      v-bind="{ ...(dogEarColor ? { gradientColor: dogEarColor } : {}) }"
      vertical
      @onCardClick="$emit('onSideNavClick')"
    >
      {{ sideNavLabel }}
    </ConstitutionCard>

    <section class="page-frame__content">
      <slot></slot>
    </section>
  </main>
</template>

<script>
import ConstitutionCard from "./ConstitutionCard.vue";

export default {
  components: {
    ConstitutionCard,
  },
  props: {
    sideNavLabel: String,
    color: {
      type: String,
      default: "var(--primary-light)",
    },
    dogEarColor: String,
    illustration: String,
  },
};
</script>

<style lang="scss" scoped>
.page-frame {
  --left-side-width: var(--content-width, 69%);
  $side-nav-width: toRem(95);

  --frame-height: calc(var(--vh, 1vh) * 100);

  background-image: linear-gradient(
    90deg,
    var(--content-color, #{$basic-white}) var(--left-side-width),
    var(--page-color) var(--left-side-width)
  );
  min-height: var(--frame-height);
  display: flex;

  &__illustration {
    position: fixed;
    width: calc(100% - var(--left-side-width));
    height: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    top: 0;
    right: 0;
  }

  &__side-nav {
    @include title-h-4;

    --dog-ear-size: #{$side-nav-height};

    width: $side-nav-height;
    height: var(--frame-height);
    position: fixed !important;
    align-items: center;
    text-transform: uppercase;
  }

  &__content {
    @include shadow-2;

    width: var(--content-width, #{var(--left-side-width)});
    padding: toRem(61) toRem(100);
    padding-left: calc(#{toRem(126)} + #{$side-nav-width});
    border-left: toRem(1) solid $basic-dark-grey;
    overflow: visible;
  }
}
</style>

<style lang="scss">
.page-frame__side-nav {
  --card-color: var(--page-color);

  .constitution-card__content {
    @include flip-text;

    padding-left: 0;
  }
}

.desktop {
  .page-frame {
    --left-side-width: 72%;

    &__illustration {
      z-index: 0;
    }

    &__content {
      padding: toRem(39) toRem(24);
      padding-left: toRem(122);
      z-index: 1;
      background-color: var(--basic-white);
      border-left: none;
    }

    &__side-nav {
      justify-content: flex-end;
      width: toRem(61);
      padding-top: toRem(90);
      filter: none;
      border: none;
      border-radius: toRem(4);
      z-index: 2;
    }
  }
}
</style>
