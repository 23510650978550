<template>
  <PageFrame
    :sideNavLabel="content.ctas.back"
    @onSideNavClick="backToPreviousRoute"
    :color="getColorDisposizione('light', articleData)"
    :dogEarColor="getColorDisposizione('dark', articleData)"
    :illustration="panelIllustration"
    v-bind:style="{
      '--card-border': getColorDisposizione('dark', articleData),
    }"
  >
    <div class="article-page__landmarks">
      <ArticleLandmark :textNr="articleData.partNr" :text="articleData.part" />

      <ArticleLandmark
        v-if="articleData.title"
        :textNr="articleData.titleNr"
        :text="articleData.title"
        withArrow
      />

      <ArticleLandmark
        v-if="articleData.section && !articleData.noSection"
        :textNr="articleData.sectionNr"
        :text="articleData.section"
        withArrow
      />

      <div class="article-page__landmarks__buttons" v-if="desktop">
        <button
          v-for="(article, i) of prevNextArticles"
          :key="article.articlesTitle"
          :class="[
            'article-page__landmarks__buttons__button',
            { prev: i === 0 },
          ]"
          @click="showArticle(article.id)"
        >
          <svgicon
            name="chevron"
            height="1.563rem"
            color="var(--primary-dark)"
            :class="[
              'article-page__landmarks__buttons__button__icon',
              { prev: i === 0 },
            ]"
          ></svgicon>
          <div class="article-page__landmarks__buttons__button__texts">
            <p class="article-page__landmarks__buttons__button__texts__label">
              {{ i === 0 ? "precedente" : "successivo" }}
            </p>
            <p class="article-page__landmarks__buttons__button__texts__art">
              {{ content.articlesTitle }}
              {{ getStrippedArticleNr(article.n_articolo) }}
            </p>
          </div>
        </button>
      </div>
    </div>

    <div class="article-page__themes" v-if="desktop">
      <span
        v-for="label of articleData.labels"
        :key="label"
        :style="{
          '--theme-color': `var(--${label})`,
        }"
        class="article-page__themes__theme"
        >{{ label.slice(5) }}</span
      >
    </div>

    <p class="article-page__words" v-if="desktop">
      Parole in media per periodo
      <span class="article-page__words__number">{{
        articleData.averageWordsPerSentence.toFixed(1)
      }}</span>
    </p>

    <h1 class="article-page__title" v-if="desktop">
      {{ this.articleData.isDisposizione ? "" : content.title }}
      {{ getStrippedArticleNr(articleData.n_articolo) }}
    </h1>

    <h1 class="article-page__title" v-if="$tvaMq !== 'desktop'">
      {{ this.articleData.isDisposizione ? "" : content.title }}
      {{ getStrippedArticleNr(articleData.n_articolo) }}

      <div
        v-for="(label, i) of articleData.labels"
        :key="label"
        class="article-page__title__theme-bubble"
        :style="{
          '--theme-color': `var(--${label})`,
          '--translation-x': i * 65 + '%',
        }"
      />
    </h1>

    <div class="article-page__paragraphs">
      <ArticleParagraph
        v-for="(paragraph, i) of articleParagraphs"
        :key="'par_' + i"
        v-bind="paragraph"
      />
    </div>

    <div class="article-page__articles" v-if="$tvaMq !== 'desktop'">
      <p class="article-page__articles__label">{{ content.prevNextText }}</p>

      <ConstitutionCard
        v-for="article of prevNextArticles"
        :key="article.id"
        class="article-page__article"
        :gradientColor="getColorDisposizione('dark', article)"
        v-bind:style="{
          '--card-color': getColorDisposizione('light', article),
        }"
        @onCardClick="showArticle(article.id)"
      >
        {{ article.isDisposizione ? "" : content.articlesTitle }}
        {{ getStrippedArticleNr(article.n_articolo) }}
      </ConstitutionCard>
    </div>

    <div v-if="articlesByLabel.length !== 0" class="article-page__articles">
      <div
        class="article-page__articles__container"
        v-for="(articlesAndLabels, i) of articlesByLabel"
        :key="'articleslabels_' + i"
      >
        <ArticleLabels :themes="articlesAndLabels.labels" />
        <ConstitutionCard
          v-for="article of articlesAndLabels.articles"
          :key="article.id"
          class="article-page__article"
          :gradientColor="getColorDisposizione('dark', article)"
          v-bind:style="{
            '--card-color': getColorDisposizione('light', article),
          }"
          @onCardClick="showArticle(article.id)"
        >
          {{ content.articlesTitle }}
          {{ getStrippedArticleNr(article.n_articolo) }}
        </ConstitutionCard>
      </div>
    </div>

    <div class="article-page__articles buttons" v-if="desktop">
      <button
        v-for="(article, i) of prevNextArticles"
        :key="article.id"
        :class="['article-page__articles__button', { next: i > 0 }]"
        @click="showArticle(article.id)"
      >
        <p class="article-page__articles__button__label">
          {{
            i === 0 ? "vai all’articolo precedente" : "vai al prossimo articolo"
          }}
        </p>
        <svgicon
          name="long-arrow"
          class="article-page__articles__button__icon"
          color=" var(--primary-dark)"
        ></svgicon>
        {{ article.isDisposizione ? "" : content.articlesTitle }}
        {{ getStrippedArticleNr(article.n_articolo) }}
      </button>
    </div>
  </PageFrame>
</template>

<script>
import constitutionData from "@/data/data-prepared.json";
import ArticleParagraph from "../components/ArticleParagraph.vue";
import ArticleLabels from "../components/ArticleLabels.vue";
import ConstitutionCard from "../components/ConstitutionCard.vue";
import ArticleLandmark from "../components/ArticleLandmark.vue";
import PageFrame from "../components/PageFrame.vue";

import { formatArticleNumber } from "../utilities/functions/formatters";
import formatNumber from "../mixins/formatNumber";
import { mapGetters } from "vuex";

export default {
  mixins: [formatNumber],
  data() {
    return {
      articleId: null,
    };
  },
  created() {
    this.articleId = this.$route.query.articleId;
  },
  mounted() {
    this.$nextTick(() => scrollTo(0, 0));
    console.log(this.articleData.averageWordsPerSentence);
  },
  computed: {
    desktop() {
      return this.$tvaMq === "desktop" || this.$tvaMq === "large";
    },
    content() {
      return this.$t("articlePage");
    },
    panelIllustration() {
      return `panel-${Math.ceil(Math.random() * 3)}${
        this.desktop ? "-large" : ""
      }`;
    },
    articleData() {
      if (this.articleId) {
        return constitutionData.find((datum) => datum.id === this.articleId);
      }
      return null;
    },
    articleParagraphs() {
      if (this.articleData) {
        return this.articleData.testo_articolo
          .split("\n")
          .map((paragraph, paragraphIndex) => {
            return {
              text: paragraph,
              kpis: this.content.kpis.map((label) => ({
                label,
                value: paragraphIndex + 1,
              })),
            };
          });
      }
      return [];
    },

    articlesByLabel() {
      if (this.articleData) {
        // Get all articles that contain one or more labels of the current article
        const articlesWithSameLabel = constitutionData.filter(
          (datum) =>
            datum.labels.some((label) =>
              this.articleData.labels.includes(label)
            ) &&
            (!this.selectedLabel || datum.labels.includes(this.selectedLabel))
        );

        // Returns an array of arrays of articles grouped under their labels.
        // If an article has multiple labels overlapping with the current article,
        // it will be grouped under all those labels
        return articlesWithSameLabel.reduce((prevLabelArrays, nextArticle) => {
          // Don't show the current article among the articles with the same label
          if (nextArticle.id === this.articleData.id) return prevLabelArrays;

          const labelsIntersection = nextArticle.labels.filter((label) =>
            this.articleData.labels.includes(label)
          );

          const existingLabelArray = prevLabelArrays.find((arr) =>
            arr.labels.every((label) => labelsIntersection.includes(label))
          );

          if (existingLabelArray) {
            existingLabelArray.articles.push(nextArticle);
          } else {
            prevLabelArrays.push({
              labels: labelsIntersection,
              articles: [nextArticle],
            });
          }

          return prevLabelArrays;
        }, []);
      }

      return [];
    },
    prevNextArticles() {
      if (this.articleId) {
        // If the viz is filtered, return prev and next article from filtered selection
        if (this.selectedLabel) {
          const allArticlesWithSameLabel = this.articlesByLabel
            .map((article) => article.articles)
            .flat()
            .filter((article) => article.labels.includes(this.selectedLabel));

          const prevNext = [];
          allArticlesWithSameLabel.forEach((article) => {
            // Filter out articles that are closest to the current article
            // by checking for each article id if it's bigger than the current article id
            // but smaller than all the others, or vice versa
            if (
              +article.id < +this.articleId &&
              (!prevNext[0] || +article.id > +prevNext[0].id)
            ) {
              prevNext[0] = article;
            }
            if (
              +article.id > +this.articleId &&
              (!prevNext[1] || +article.id < +prevNext[1].id)
            ) {
              prevNext[1] = article;
            }
          });
          return prevNext.filter((art) => !!art);
        } else {
          // Return previous and next article as calculated from article ids
          return constitutionData.filter((datum) => {
            return (
              +datum.id === +this.articleId - 1 ||
              +datum.id === +this.articleId + 1
            );
          });
        }
      }

      return [];
    },
    ...mapGetters(["getVizElSelected", "selectedLabel"]),
  },
  methods: {
    getColorDisposizione(type, article) {
      return article.isDisposizione
        ? `var(--basic-${type}-grey)`
        : `var(--primary-${type})`;
    },
    showArticle(articleId) {
      this.$router
        .push({ name: "article", query: { articleId } })
        // This is to catch the error that is thrown on navigation to the same route
        // since we actually want to navigate to same route
        .catch(() => false);

      this.articleId = articleId;
      scrollTo(0, 0);
    },
    getStrippedArticleNr(articleNr) {
      return formatArticleNumber(articleNr);
    },
    backToPreviousRoute() {
      const query = this.getVizElSelected
        ? { type: 2, id: this.getVizElSelected.data.id }
        : {};

      this.$router.push({
        name: "constitution-page",
        query,
      });
    },
  },
  components: {
    ArticleParagraph,
    ArticleLabels,
    ConstitutionCard,
    ArticleLandmark,
    PageFrame,
  },
};
</script>

<style lang="scss" scoped>
.article-page {
  &__landmarks {
    display: flex;
    margin-bottom: toRem(82);

    > * {
      margin-right: 1rem;
    }
  }

  &__title {
    @include title-h-0;

    margin-bottom: toRem(81);
    position: relative;
    width: fit-content;

    &__theme-bubble {
      position: absolute;
      top: 50%;
      right: -3rem;
      width: toRem(23);
      height: toRem(48);
      border-radius: toRem(10);
      background-color: var(--theme-color);
      transform: translate(var(--translation-x), -50%);
    }
  }

  &__paragraphs {
    margin-bottom: toRem(153);
  }

  &__articles {
    width: 65rem;
    margin-bottom: toRem(51);

    &__label {
      @include button-m;

      margin-bottom: toRem(32);
    }

    &__container {
      margin-bottom: toRem(51);
    }
  }

  &__article {
    margin-bottom: toRem(10);
    width: 90%;
  }

  &__scroll-indicator {
    position: fixed;
    bottom: toRem(60);
    right: toRem(167);
  }
}

.desktop {
  .article-page-frame {
  }

  .article-page {
    &__paragraphs {
      padding-right: toRem(270);
    }

    &__article {
      filter: none;
      border-radius: toRem(4);
      border: none;
      font-size: 1.125rem;
      position: relative;
    }

    &__articles {
      width: 100%;
      padding-right: toRem(270);

      &.buttons {
        display: flex;
        justify-content: space-between;
        padding-right: toRem(170);
        margin-top: toRem(86);
      }

      &__button {
        width: 100%;
        font-family: Spectral;
        font-size: toRem(30);
        font-style: normal;
        font-weight: 800;
        line-height: 110%; /* 33px */
        letter-spacing: -0.3px;
        color: var(--basic-dark-grey);
        text-align: start;
        display: flex;
        flex-direction: column;

        &:hover {
          .article-page__articles__button__icon {
            transform: translateX(-1rem);
          }
        }

        &.next:hover {
          .article-page__articles__button__icon {
            transform: rotate(180deg) translateX(-1rem);
          }
        }

        &.next {
          position: relative;
          padding-left: 20%;
          // align-items: flex-end;

          .article-page__articles__button__icon {
            transform: rotate(180deg);
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            width: toRem(1);
            background-color: #656565;
          }
        }

        &__label {
          font-family: Inter;
          font-size: toRem(12);
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 14.4px */
          letter-spacing: 0.72px;
          text-transform: uppercase;
        }

        &__icon {
          max-width: toRem(206);
          margin-top: toRem(10);
          margin-bottom: toRem(10);
          transition: transform 0.5s;
        }
      }
    }

    &__landmarks {
      margin-bottom: toRem(59);

      & > * {
        font-size: 1rem;
      }

      &__buttons {
        margin-left: auto;
        display: flex;

        &__button {
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          text-align: start;

          &.prev {
            flex-direction: row;

            .article-page__landmarks__buttons__button__texts {
              margin-right: toRem(40);
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                right: toRem(-20);
                height: 100%;
                width: 1px;
                background-color: var(--basic-dark-grey);
              }
            }
          }

          &__icon {
            margin-left: toRem(5);
            &.prev {
              margin-right: toRem(5);
              transform: rotate(180deg);
            }
          }

          &__texts {
            &__label {
              font-family: Inter;
              font-size: 0.75rem;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 14.4px */
              letter-spacing: 0.72px;
              text-transform: uppercase;
            }

            &__art {
            }
          }
        }
      }
    }

    &__themes {
      &__theme {
        border-radius: toRem(5);
        padding: toRem(5);
        background-color: var(--theme-color);
        font-family: Inter;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.2px;
        text-transform: uppercase;

        &:not(:first-of-type) {
          margin-left: toRem(10);
        }
      }
    }

    &__words {
      margin-top: toRem(24);
      font-family: Spectral;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      letter-spacing: -0.32px;

      &__number {
        @include kpi-l;
        font-size: 1.375rem;
        font-style: italic;
      }
    }

    &__title {
      font-size: 5.9rem;
      margin-top: toRem(20);
      margin-bottom: toRem(20);
    }
  }
}
</style>
